<script setup lang="ts">
    import { type CustomerQuery } from '~/graphql/generated-customer';

    const props = defineProps<{ profile: CustomerQuery['customer']; refresh: () => Promise<void> }>();
    const checkboxRidersClubValue = ref();
    const loading = ref(false);
    watch(
        () => props.profile,
        (newValue) => {
            checkboxRidersClubValue.value = newValue.ridersClub?.value;
        },
        {
            immediate: true,
        },
    );
</script>
<template>
    <mol-account-form
        form-action="/riders-club-consent"
        :refresh="refresh"
        hide-buttons
        @loading="loading = $event"
        class="mol-account-consent-riders-club">
        <div
            :class="[
                ' rounded-lg border-2 border-woom-yellow  p-4 ',
                profile.ridersClub?.value === 'true' ? 'border-opacity-0 bg-woom-white' : 'bg-woom-yellow/15',
            ]">
            <input
                type="hidden"
                name="customerId"
                :value="profile.id" />
            <input
                type="hidden"
                name="metaFieldId"
                :value="profile.ridersClub?.id" />
            <input
                type="hidden"
                name="metaFieldDateId"
                :value="profile.ridersClubDate?.id" />
            <div class="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <mol-form-checkbox
                    v-model:checked-value="checkboxRidersClubValue"
                    class="font-bold"
                    for-id="metaFieldValue"
                    :label="$t('account.ridersClub.messageConsent')"
                    true-value="true"
                    false-value="false"
                    no-margin />
                <atm-button
                    :disabled="checkboxRidersClubValue === profile.ridersClub?.value"
                    :loading="loading"
                    styling="solid-secondary">
                    {{ $t('cta.account.save') }}
                </atm-button>
            </div>

            <atm-alert
                v-if="profile.ridersClub?.value !== 'true'"
                class="mt-4"
                :message="$t('account.ridersClub.messageConcentDetail')"
                theme="warning" />
        </div>
    </mol-account-form>
</template>

<script setup lang="ts">
    import type { CustomerAddressFieldsFragment, CustomerQuery } from '~/graphql/generated-customer';

    interface AddressProps {
        addresses: CustomerQuery['customer']['addresses'];
        defaultAddress: CustomerQuery['customer']['defaultAddress'];
        refresh: () => Promise<void>;
    }

    defineProps<AddressProps>();

    const emit = defineEmits<{
        (e: 'update:dialog', data: { address: CustomerAddressFieldsFragment; isDefaultAddress: boolean }): void;
    }>();

    const handleEdit = (address?: CustomerAddressFieldsFragment | null, isDefaultAddress?: boolean = false) => {
        if (address) emit('update:dialog', { address, isDefaultAddress });
    };
</script>

<template>
    <div
        v-if="addresses || defaultAddress"
        class="mol-account-address flex flex-col gap-4 md:grid md:grid-cols-3">
        <atm-edit-card
            v-if="defaultAddress"
            @edit="handleEdit(defaultAddress, true)">
            <address class="not-italic">
                <i>{{ $t('account.address.defaultShort') }}</i>
                <br />
                <span>{{ defaultAddress.firstName }} {{ defaultAddress.lastName }} </span>
                <br />
                <span>{{ defaultAddress.address1 }}</span>
                <br />
                <span>{{ defaultAddress.address2 }}</span>
                <br />
                <span>{{ defaultAddress.zip }}</span>
                &nbsp;
                <span>{{ defaultAddress.city }}</span>
                <br />
                <span v-if="defaultAddress.country">
                    {{ defaultAddress.country }}
                </span>
                <br />
                <span>{{ defaultAddress.phoneNumber }}</span>
            </address>
        </atm-edit-card>

        <template
            v-if="addresses.edges"
            v-for="(address, index) in addresses.edges">
            <atm-edit-card @edit="handleEdit(address.node)">
                <address class="not-italic">
                    <span>{{ address.node.firstName }} {{ address.node.lastName }}</span>
                    <br />
                    <span>{{ address.node.address1 }}</span>
                    <br />
                    <span>{{ address.node.address2 }}</span>
                    <br />
                    <span>{{ address.node.zip }}</span>
                    &nbsp;
                    <span>{{ address.node.city }}</span>
                    <br />
                    <span v-if="address.node.country">
                        {{ address.node.country }}
                    </span>
                    <br />
                    <span>{{ address.node.phoneNumber }}</span>
                </address>
            </atm-edit-card>
        </template>
    </div>
</template>

<script setup lang="ts">
    interface IfcountryProps {
        countries: string[];
    }
    const props = defineProps<IfcountryProps>();
    const { iso2Country } = useLocales();
    const countryAllowed = computed(() => props.countries.some((country) => country.toLowerCase() === iso2Country.value.toLowerCase()));
</script>
<template>
    <template v-if="countryAllowed">
        <slot></slot>
    </template>
</template>

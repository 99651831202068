<script setup lang="ts">
    import { FetchError } from 'ofetch';

    interface AccountFormProps {
        formAction?: string;
        refresh: () => Promise<void>;
        presubmitHook?: (event: Event) => Promise<void>;
        hideButtons?: boolean;
    }
    const props = defineProps<AccountFormProps>();

    const emit = defineEmits<{
        (e: 'loading', data: boolean): void;
    }>();
    const nuxtApp = useNuxtApp();
    const { locale, t } = useLocales();

    const errorMessage = ref('');
    const errorShopify = ref('');
    const savingInProgress = ref(false);

    const userBaseRoute = computed(() => `/${locale.value}/customer`);

    watch(savingInProgress, (newValue) => emit('loading', newValue));

    const handleFormChanges = async (event: Event) => {
        if (props.presubmitHook) await props.presubmitHook(event);

        errorMessage.value = '';
        errorShopify.value = '';
        const form = event.currentTarget as HTMLFormElement;

        try {
            const formData = Object.fromEntries(new FormData(form).entries());
            savingInProgress.value = true;

            await $fetch(form.action, {
                // @ts-ignore - method from form will always be string
                method: form.method,
                body: formData,
            });

            await props.refresh();
            savingInProgress.value = false;
            closeDialog();
        } catch (error) {
            savingInProgress.value = false;
            errorMessage.value = 'forms.submitError';
            if (error instanceof FetchError) {
                if (!error.response) return;
                const parsedError = error.response._data;
                // TODO: better handle of form validation to inform user which fields has what issue
                if (parsedError?.message) errorShopify.value = parsedError.message;
                console.error(parsedError?.message);
                return;
            }
        }
    };

    const closeDialog = () => {
        nuxtApp.$eventEmitter.emit('account-dialog', {
            openDialog: false,
        });
        return false;
    };
</script>

<template>
    <form
        class="mol-account-form flex flex-col gap-4"
        method="post"
        :action="`${userBaseRoute}${formAction}`"
        @submit.prevent="handleFormChanges">
        <slot></slot>

        <atm-form-error
            class="text-woom-red"
            :error="$t(errorMessage)"
            no-indent />
        <atm-form-error
            class="text-woom-red"
            :error="errorShopify"
            no-indent />

        <div
            v-if="!hideButtons"
            class="flex gap-4 max-md:flex-col md:justify-between">
            <div class="flex gap-4 max-md:flex-col md:order-2 md:ml-auto">
                <atm-button
                    styling="text"
                    :disabled="savingInProgress"
                    @click="closeDialog"
                    type="reset">
                    {{ $t('cta.account.cancel') }}
                </atm-button>
                <atm-button
                    :loading="savingInProgress && errorMessage.length === 0"
                    styling="solid-secondary">
                    {{ $t('cta.account.save') }}
                </atm-button>
            </div>
            <slot
                name="deleteForm"
                :handleFormChanges="handleFormChanges"></slot>
        </div>
    </form>
</template>

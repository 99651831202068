<script setup lang="ts">
    import { LazyMolAccountDialogBike, LazyMolAccountDialogAddress, LazyMolAccountDialogKid, LazyMolAccountDialogProfile } from '#components';
    import type { AccountBike } from '~/components/mol/AccountBikes.vue';
    import type { AccountDialogAddressProps } from '~/components/mol/AccountDialogAddress.vue';
    import type { AccountDialogBikeProps } from '~/components/mol/AccountDialogBike.vue';
    import type { AccountDialogKidProps } from '~/components/mol/AccountDialogKid.vue';
    import type { AccountDialogProfileProps } from '~/components/mol/AccountDialogProfile.vue';
    import type { AccountKid } from '~/components/mol/AccountKids.vue';
    import type { CustomerAddressFieldsFragment, CustomerQuery } from '~/graphql/generated-customer';

    definePageMeta({
        middleware: ['auth-guard'],
    });
    const nuxtApp = useNuxtApp();
    const { t } = useLocales();
    const localPath = useLocalePathWoom();
    const { contactUrl } = useURL();

    if (import.meta.server) {
        if (nuxtApp.ssrContext) {
            nuxtApp.ssrContext.event.node.res.setHeader('Cache-Control', 'no-cache');
        }
    }

    // https://nuxt.com/docs/getting-started/data-fetching#client-only-fetching
    const { data, pending, error, refresh } = await useFetch<CustomerQuery['customer']>(localPath('/customer'), {
        method: 'POST',
        key: 'customer',
        lazy: true,
        server: false,
    });

    watch(error, (newError) => console.error(newError));

    const openAccountDialog = (title: string, content: Events.ComponentWithProps) => {
        nuxtApp.$eventEmitter.emit('account-dialog', {
            title: t(title),
            content,
        });
    };

    type DialogConfigKeys = 'profile' | 'bikes' | 'kids' | 'address';
    const openDialog = (identifier: DialogConfigKeys) => {
        if (!data.value) return;
        const dialogConfig: Record<DialogConfigKeys, { title: string; content: Events.ComponentWithProps }> = {
            profile: {
                title: 'account.profile.title',
                content: {
                    component: LazyMolAccountDialogProfile,
                    props: {
                        customer: data.value,
                        refresh,
                    } satisfies AccountDialogProfileProps,
                },
            },
            bikes: {
                title: 'account.bikes.title',
                content: {
                    component: LazyMolAccountDialogBike,
                    props: {
                        customerId: data.value?.id ?? '',
                        customerBikes: data.value?.bikes,
                        refresh,
                    } satisfies AccountDialogBikeProps,
                },
            },
            kids: {
                title: 'account.kids.title',
                content: {
                    component: LazyMolAccountDialogKid,
                    props: {
                        customerId: data.value?.id ?? '',
                        customerKids: data.value?.kids,
                        refresh,
                    } satisfies AccountDialogKidProps,
                },
            },
            address: {
                title: 'account.address.title',
                content: {
                    component: LazyMolAccountDialogAddress,
                    props: { refresh } satisfies AccountDialogAddressProps,
                },
            },
        };
        const config = dialogConfig[identifier];
        if (!config) return;
        openAccountDialog(config.title, config.content);
    };

    const handleUpdateBike = ({ bike, index }: { bike: AccountBike; index: number }) => {
        openAccountDialog('account.bikes.title', {
            component: LazyMolAccountDialogBike,
            props: {
                bike,
                bikeIndex: index,
                customerId: data.value?.id ?? '',
                customerBikes: data.value?.bikes,
                refresh,
            } satisfies AccountDialogBikeProps,
        });
    };

    const handleUpdateKid = ({ kid, index }: { kid: AccountKid; index: number }) => {
        openAccountDialog('account.kids.title', {
            component: LazyMolAccountDialogKid,
            props: {
                kid: kid,
                kidIndex: index,
                customerId: data.value?.id ?? '',
                customerKids: data.value?.kids,
                refresh: refresh,
            } satisfies AccountDialogKidProps,
        });
    };

    const handleUpdateAddress = ({ address, isDefaultAddress }: { address: CustomerAddressFieldsFragment; isDefaultAddress: boolean }) => {
        openAccountDialog('account.address.title', {
            component: LazyMolAccountDialogAddress,
            props: {
                address,
                isDefaultAddress,
                refresh,
            } satisfies AccountDialogAddressProps,
        });
    };

    const limitReached = (json?: string | null) => {
        if (!json) return;
        try {
            const parsed = JSON.parse(json);
            return parsed.length >= 5;
        } catch (err) {
            console.error(err);
            return;
        }
    };
</script>

<template>
    <div
        v-if="data"
        class="bg-woom-white-smoke py-10 md:py-14">
        <atm-grid>
            <div class="col-span-2 md:col-span-12">
                <atm-heading
                    level="h1"
                    class="mb-9">
                    {{ $t('account.heading', { nameWithSpace: ` ${data.firstName ?? ''}`.trimEnd() }) }}
                </atm-heading>

                <div class="flex flex-col gap-11 md:gap-14">
                    <mol-account-section
                        :title="$t('account.profile.title')"
                        :section-title="$t('account.profile.sectionTitle')"
                        section-image="/account/profile.png"
                        :description="$t('account.profile.description')"
                        @open:dialog="openDialog('profile')"
                        is-profile
                        show-edit>
                        <atm-account-profile
                            :profile="data"
                            :refresh="refresh" />
                    </mol-account-section>

                    <mol-account-section
                        :title="$t('account.address.title')"
                        :section-title="$t('account.address.sectionTitle')"
                        section-image="/account/address.png"
                        :description="$t('account.address.description')"
                        :no-content-message="$t('account.address.empty')"
                        :no-content-message-show="!data.defaultAddress?.id"
                        @open:dialog="openDialog('address')">
                        <mol-account-address
                            :addresses="data.addresses"
                            :default-address="data.defaultAddress"
                            :refresh="refresh"
                            @update:dialog="handleUpdateAddress" />
                    </mol-account-section>
                </div>
            </div>
        </atm-grid>

        <atm-grid no-margin>
            <div class="col-span-2 my-14 h-10 bg-[url('/account/ridersclub-spacer.png')] bg-center bg-repeat-x md:col-span-12"></div>
        </atm-grid>

        <atm-grid>
            <div class="col-span-2 md:col-span-12">
                <atm-heading
                    level="h2"
                    class="mb-9">
                    {{
                        data.ridersClub?.value !== 'true'
                            ? $t('account.ridersClub.headingNo')
                            : `${$t('account.ridersClub.headingYes')} ${data.firstName ?? ''}`
                    }}
                </atm-heading>

                <div class="flex flex-col gap-11 md:gap-14">
                    <org-paginated-swiper />

                    <mol-account-consent-riders-club
                        :profile="data"
                        :refresh="refresh" />

                    <div
                        :class="[
                            'flex flex-col gap-11 md:gap-14',
                            { 'cursor-not-allowed opacity-30 [&_button]:pointer-events-none': data.ridersClub?.value !== 'true' },
                        ]">
                        <mol-account-section
                            :title="$t('account.bikes.title')"
                            :section-title="$t('account.bikes.sectionTitle')"
                            section-image="/account/ridersclub-bikes.png"
                            :description="$t('account.bikes.description')"
                            :no-content-message="$t('account.bikes.empty')"
                            :no-content-message-show="!data.bikes?.value"
                            :disable-button="limitReached(data.bikes?.value)"
                            @open:dialog="openDialog('bikes')">
                            <mol-account-bikes
                                :bikes="data.bikes"
                                :id="data.id"
                                :refresh="refresh"
                                @update:bike="handleUpdateBike" />
                        </mol-account-section>

                        <mol-account-section
                            :title="$t('account.kids.title')"
                            :section-title="$t('account.kids.sectionTitle')"
                            section-image="/account/ridersclub-kids.png"
                            :description="$t('account.kids.description')"
                            :no-content-message="$t('account.kids.empty')"
                            :no-content-message-show="!data.kids?.value"
                            :disable-button="limitReached(data.kids?.value)"
                            @open:dialog="openDialog('kids')">
                            <mol-account-kids
                                :kids="data.kids"
                                :id="data.id"
                                :refresh="refresh"
                                @update:kid="handleUpdateKid" />
                        </mol-account-section>
                        <atm-if-country :countries="['at', 'de']">
                            <mol-account-section
                                :title="$t('account.productTester.title')"
                                section-image="/account/ridersclub-kids.png"
                                :description="$t('account.productTester.description')">
                                <template #actions>
                                    <atm-button
                                        :to="$t('account.productTester.url')"
                                        styling="solid-secondary"
                                        kind="link">
                                        {{ $t('account.productTester.cta') }}
                                    </atm-button>
                                    <atm-alert
                                        :message="$t('account.productTester.messageParticipation')"
                                        :theme="'warning'" />
                                </template>
                            </mol-account-section>
                        </atm-if-country>
                    </div>

                    <div class="flex flex-col gap-2">
                        <p>
                            {{ $t('account.footer.questions.start') }}
                            <nuxt-link
                                :to="contactUrl"
                                class="underline transition-colors hover:text-woom-red">
                                {{ $t('account.footer.questions.middle') }}
                            </nuxt-link>
                            {{ $t('account.footer.questions.end') }}
                        </p>

                        <p class="link-style">
                            <renderer-html :content="woomTextFormat($t('account.faq'))" />
                        </p>
                    </div>
                </div>
            </div>
        </atm-grid>
    </div>
    <mol-error
        v-else-if="error"
        :status-code="503" />
    <lazy-atm-loader-bike
        v-else
        class="absolute h-full w-full" />
</template>
